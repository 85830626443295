import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
//import { localize } from "src/components/language/config";
// reactstrap components
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  UncontrolledCollapse,
  CardBody,
  CardTitle,
  CardFooter,
} from "reactstrap";

import GbImage from "src/components/Custom/gbImage.js";
import GiftUp from "src/components/Custom/GiftUp/GiftUp.js";

// core components

const ShopCard = company => {

  const data = useStaticQuery(graphql`
    query {
      allGoogleSpreadsheetShopAngebote(filter: {shopName: {eq: "KHT"}, status: {eq: "Aktiv"}}, sort: {fields: ranking}) {
        edges {
          node {
            text
            titel
            link
            shopName
            ranking
            bild
          }
        }
      }
    }
  `);

  const img = company.bildWebsite
    ? "shops/" + company.bildWebsite
    : "shops/citylove/Voucher White@1x.jpg";

  const [collapseMore, setCollapseMore] = React.useState(false);

  const openLink = () => {
    if(window.gtag) window.gtag('event', 'business', {
      'event_category' : 'openLink',
      'event_label' : company.website
    });

  }

  const openOffer = (link) => {
    if(window.gtag) window.gtag('event', 'business', {
      'event_category' : 'openOffer',
      'event_label' : company.website + ': ' + link
    });
    window.open(link, "_blank"); 

  }


  const getOffers = () => {
      
    //console.log('getOffers', data.allGoogleSpreadsheetShopAngebote);
    if(!data.allGoogleSpreadsheetShopAngebote.edges) return;

    return data.allGoogleSpreadsheetShopAngebote.edges.filter(aOffer => aOffer.node.shopName === company.name).map(aOffer => {
      const offerImg = aOffer.node.bild
      ? "shops/" + aOffer.node.bild
      : "shops/citylove/Voucher White@1x.jpg";

      return (
        <Col key={aOffer.node.ranking} sm="">
          <Card className="card-blog">
            <CardBody className="">
              <h5 className="card-blog-title" dangerouslySetInnerHTML={{
             __html: aOffer.node.titel}} />
              <p className="card-blog-description" dangerouslySetInnerHTML={{
             __html: aOffer.node.text}} />
              <CardFooter>
                {aOffer.node.link && <Button
                  className="btn-outline-primary btn-round"
                  color="danger"
                  href="#"
                  onClick={openOffer.bind(this, aOffer.node.link)}
                >
                  Link <i className="nc-icon nc-minimal-right"></i>
                </Button> }
              </CardFooter>
            </CardBody>
            <div className="card-image">
              <a href="#" onClick={openOffer.bind(this, aOffer.node.link)}>
                <GbImage className="img" alt="alt" src={offerImg}></GbImage>
              </a>
            </div>
          </Card>
        </Col>
      );
    });

  }


  return (
    <>
      <Card  className="card-citylove-result-2">
        <a href="#"
            style={{
                backgroundImage: "url(https://source.unsplash.com/random/600x600)"
            }}
            >
            {/* <GbImage alt="alt" src={img}></GbImage> */}
          <CardBody>
            <p className="citylove-result-pills">
              <span className="badge badge-default badge-pill">
                {company.type}
              </span>
            </p>
            <CardTitle tag="h3">{company.name}</CardTitle>
          </CardBody>
        </a>
      </Card>
    </>
  );
};

export default ShopCard;
